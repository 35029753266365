@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap);
body {
  margin: 0;
  font-family: 'Rubik';
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: center;

}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #fff;
  color: #000;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}
.mintLoader {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #fff;
  color: #000;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
  background: #eee;
  display:inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%);
  display: none;
}
#title {
  padding-top: 32px;
}

#status {
  color:black;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: right;
  float:right;
  line-height: 16px;
}

#mintButton {
  margin-top: 40px;
  background-color: ;
  color:black;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.nftimg {
  width: 50%;
  display: block;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 0px 0px 10px #ccc;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

